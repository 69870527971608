<template>
  <div id="app">
    <router-view class="content" />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "captrain-signature-app",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    const data = localStorage.getItem("vuex") || "";
    const token = data ? JSON.parse(data).account.token : "";
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {},
};
</script>

<style lang='scss'>
@import "~@/styles/base";
@import "~@/styles/mixins";
@import "~@/styles/typography";

.content {
  // margin-bottom: 5px;
  // min-height: calc(100vh - 452px);
}
</style>