import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'


import * as types from '@/vuex/types'

Vue.use(Vuex)

const getters = {
  account: state => state.account
}

export default new Vuex.Store({
  getters,
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {},
    account: ''
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, token, user) {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.account = ''
    },
    [types.GET_ACCOUNT](state, response) {
      state.account = response.data
      return response.data;
    },
    [types.REMOVE_ACCOUNT](state, response) {
      state.account = ''
    },
  },
  actions: {
    async login({
      commit
    }, payload) {
      return axios
        .post("api/login", payload)
        .then(response => {
          const {
            token
          } = response.data.data;
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          return commit(types.GET_ACCOUNT, response.data)
        })
        .catch(error => {
          console.log('error store', error)
          return false;
        });
    },
    async logout({
      commit
    }, payload) {
      commit(types.REMOVE_ACCOUNT)
    },

  },
  plugins: [createPersistedState({
    reducer: state => ({
      account: state.account
    })
  })],
})