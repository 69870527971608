import Vue from 'vue'
// import 'vuetify/dist/vuetify.min.css'
import App from './App.vue'
import axios from 'axios'
import store from './store'
import './registerServiceWorker'

import router from './router'


// Axios
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = 'https://capcms.geb-staging.de/';

// UI Components
import vSelect from 'vue-select'
import VueClipboard from 'vue-clipboard2'
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

Vue.use(VueClipboard)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')