import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'

Vue.use(Router)

const router = new Router({
    routes: [{
        path: '/',
        name: 'login',
        component: () => import( /* webpackChunkName: "login" */ './views/Login')
    }, {
        path: '/form',
        name: 'form',
        component: () => import( /* webpackChunkName: "form" */ './views/Form'),
        props: true,
        meta: {
            isLogin: true
        }
    }, {
        path: '/signature',
        name: 'signature',
        props: true,
        component: () => import( /* webpackChunkName: "signature" */ './views/Signature'),
        meta: {
            isLogin: true
        }
    }, {
        path: '/tutorial',
        name: 'tutorial',
        props: true,
        component: () => import( /* webpackChunkName: "tutorial" */ './views/Tutorial'),
        meta: {
            isLogin: true
        }
    }],
})

router.beforeEach((to, from, next) => {
    const token = store.getters.account.token
    console.log(store)
    if (to.matched.some(record => record.meta.isLogin)) {
        if (!token) {
            next({
                name: 'login'
            })
        } else {
            next()
        }
    } else {
        next();
    }
});

export default router;